const initialState = {
  pricingPlan: `enterprise`,
}

export const setPlan = plan => ({
  type: `SET_PLAN`,
  plan,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case `SET_PLAN`:
      return {
        ...state,
        pricingPlan: action.plan,
      }

    default:
      return state
  }
}

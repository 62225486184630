const initialState = {
  locale: {
    current: `en`,
    slugs: [],
  },
  stagger: {},
  mouse: {
    x: null,
    y: null,
  },
}

const SET_LOCALE = `SET_LOCALE`

const ADD_STAGGER = `ADD_STAGGER`
const REMOVE_STAGGER = `REMOVE_STAGGER`

export const setLocale = locale => ({
  type: SET_LOCALE,
  locale,
})

//animation stagger
export const addStagger = payload => ({
  type: ADD_STAGGER,
  payload,
})
export const removeStagger = payload => ({
  type: REMOVE_STAGGER,
  payload,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: { current: action.locale.current, slugs: action.locale.slugs },
      }

    case ADD_STAGGER:
      var obj = { ...state }

      //if group doesn't exist, add it as ampty array
      if (!obj.stagger[action.payload.group]) {
        obj.stagger[action.payload.group] = []
      }

      //add id to group, if not already there
      if (!obj.stagger[action.payload.group].includes(action.payload.id))
        obj.stagger[action.payload.group].push(action.payload.id)

      return obj

    case REMOVE_STAGGER:
      var rObj = { ...state }

      if (rObj.stagger[action.payload.group]) {
        const array = rObj.stagger[action.payload.group].filter(
          e => e !== action.payload.id
        )
        rObj.stagger[action.payload.group] = array
      }

      return rObj

    default:
      return state
  }
}

exports.components = {
  "component---src-pages-en-developer-platform-3-js": () => import("./../../../src/pages/en/developer-platform3.js" /* webpackChunkName: "component---src-pages-en-developer-platform-3-js" */),
  "component---src-pages-en-forrester-total-economic-impact-report-1-js": () => import("./../../../src/pages/en/forrester-total-economic-impact-report-1.js" /* webpackChunkName: "component---src-pages-en-forrester-total-economic-impact-report-1-js" */),
  "component---src-pages-en-where-brands-live-js": () => import("./../../../src/pages/en/where-brands-live.js" /* webpackChunkName: "component---src-pages-en-where-brands-live-js" */),
  "component---src-pages-fr-confidentialite-js": () => import("./../../../src/pages/fr/confidentialite.js" /* webpackChunkName: "component---src-pages-fr-confidentialite-js" */),
  "component---src-templates-integrations-detail-js": () => import("./../../../src/templates/integrations-detail.js" /* webpackChunkName: "component---src-templates-integrations-detail-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-resources-js": () => import("./../../../src/templates/resources.js" /* webpackChunkName: "component---src-templates-resources-js" */)
}

